<script setup lang="ts">
import VWidgetHeading from '@/components/Widgets/VWidgetHeading.vue';
import type { IAdvantages } from '@/stores/page-home-store';
import VIcon from '@/components/UI/VIcon.vue';
import { useAppStore } from '@/stores/app-store';

defineProps<{
    advantages: IAdvantages;
}>();

const appStore = useAppStore();

const colors = ['#e9fcf5', '#EDF9FD', '#FDEDED'];
</script>

<template>
    <div class="v-home-advantages">
        <div class="container">
            <VWidgetHeading
                :align="appStore.capacitor.platform === 'web' ? 'center' : 'left'"
                :subtitle="advantages.name"
                :title="appStore.capacitor.platform === 'web' ? advantages.summary : null"
                :description="appStore.capacitor.platform === 'web' ? advantages.content : null"
            />
            <div class="v-home-advantages-items">
                <template :key="index" v-for="(advantage, index) in advantages.items">
                    <div
                        class="v-home-advantages-item"
                        :style="{
                            '--bg-color': advantage.color || colors[index % 3]
                        }"
                    >
                        <div class="icon">
                            <VIcon :name="advantage.icon || 'hand-coin-line'" :size="50" />
                        </div>
                        <div class="title" v-if="advantage.title">{{ advantage.title }}</div>
                        <div class="content" v-if="advantage.content">{{ advantage.content }}</div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
