<script setup lang="ts">
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import VWidgetHeading from '@/components/Widgets/VWidgetHeading.vue';
import screen from '@/plugins/screen';
import { useAppStore } from '@/stores/app-store';
import type { Campaigns } from '@/stores/page-home-store';

defineProps<{
    campaigns: Campaigns;
}>();

const appStore = useAppStore();

const onSwiperFrameRef = ref<typeof Swiper | null>(null);
const onSwiperFrameSlideIndexRef = ref<number>(0);
const onSwiperStepperRef = ref<typeof Swiper | null>(null);
const onSwiperFrameInit = (swiper: any) => {
    onSwiperFrameRef.value = swiper as typeof Swiper;
    swiper.on('slideChange', function () {
        if (onSwiperStepperRef.value === null) return;
        onSwiperStepperRef.value.slideTo(swiper.activeIndex);
        onSwiperFrameSlideIndexRef.value = swiper.activeIndex;
    });
};

const handleStepperClick = (index: number) => {
    if (onSwiperFrameRef.value === null) return;
    onSwiperFrameRef.value.slideTo(index);
};

const onSwiperStepperInit = (swiper: any) => {
    onSwiperStepperRef.value = swiper as typeof Swiper;
    swiper.on('slideChange', function () {
        if (onSwiperFrameRef.value === null) return;
        onSwiperFrameRef.value.slideTo(swiper.activeIndex);
    });
};
</script>

<template>
    <div class="v-home-campaigns">
        <div class="container">
            <VWidgetHeading
                :align="appStore.capacitor.platform === 'web' ? 'center' : 'left'"
                :subtitle="campaigns.name"
                :title="appStore.capacitor.platform === 'web' ? campaigns.summary : null"
                :description="appStore.capacitor.platform === 'web' ? campaigns.content : null"
            />
            <div class="swipers">
                <swiper @swiper="onSwiperFrameInit" :slides-per-view="1">
                    <swiper-slide v-for="(item, i) in campaigns.items" :key="i">
                        <div class="v-home-campaigns-frame">
                            <div class="top">
                                <div class="left">
                                    <img :src="item.image" :alt="item.name || ''" loading="lazy" v-if="item.image" />
                                </div>
                                <div class="right">
                                    <div class="v-home-campaigns-stepper">
                                        <span>0{{ i + 1 }}</span>
                                        <div class="name">{{ item.name }}</div>
                                        <div class="summary">{{ item.summary }}</div>
                                    </div>
                                    <div v-if="item.content" v-html="item.content" />
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="bottom">
                    <swiper @swiper="onSwiperStepperInit" :slides-per-view="screen.isMobile ? 1 : 3" :spaceBetween="24">
                        <swiper-slide v-for="(item, i) in campaigns.items" :key="i">
                            <div
                                :class="{
                                    'v-home-campaigns-stepper': true,
                                    active: i === onSwiperFrameSlideIndexRef
                                }"
                                @click="handleStepperClick(i)"
                            >
                                <span>0{{ i + 1 }}</span>
                                <div class="title">{{ item.name }}</div>
                                <div class="summary" v-if="item.summary">{{ item.summary }}</div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>
