<script setup lang="ts">
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

import VBlogCard from '@/components/Blog/VBlogCard.vue';
import VWidgetHeading from '@/components/Widgets/VWidgetHeading.vue';
import { useAppStore } from '@/stores/app-store';
import type { IBlogs } from '@/stores/page-home-store';

defineProps<{
    blogs: IBlogs;
}>();

const appStore = useAppStore();
</script>

<template>
    <div class="v-home-blogs">
        <div class="container">
            <VWidgetHeading
                :align="appStore.capacitor.platform === 'web' ? 'center' : 'left'"
                :subtitle="blogs.name"
                :title="appStore.capacitor.platform === 'web' ? blogs.summary : null"
                :description="appStore.capacitor.platform === 'web' ? blogs.content : null"
            />
            <div class="v-home-blogs-list">
                <swiper
                    :modules="[Pagination]"
                    :pagination="true"
                    :slidesPerView="3"
                    :slidesPerGroup="3"
                    :breakpoints="{
                        0: {
                            // autoHeight: true,
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                            spaceBetween: 24
                        },
                        768: {
                            // autoHeight: true,
                            slidesPerView: 2,
                            slidesPerGroup: 1,
                            spaceBetween: 24
                        },
                        1050: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 24
                        }
                    }"
                >
                    <swiper-slide :key="index" v-for="(blog, index) in blogs.items">
                        <VBlogCard :blog="blog" />
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>
