<script setup lang="ts">
// import 'swiper/css';
// import 'swiper/css/effect-fade';

import { ref, watch } from 'vue';

import { EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

import CioLink from '@/components/CioLink.vue';
import VButton from '@/components/Forms/VButton.vue';
import VWidgetHeading from '@/components/Widgets/VWidgetHeading.vue';
import EffectLineerCebir from '@/plugins/swiper/EffectLineerCebir';
import type { ICities, ICitiesItem } from '@/stores/page-home-store';

const props = defineProps<{
    cities: ICities;
}>();

const city_items = ref<ICitiesItem[]>([]);

watch(
    () => props.cities,
    () => {
        city_items.value = [...props.cities.items, ...props.cities.items, ...props.cities.items];
    },
    { immediate: true }
);

const swiperPicturesRef = ref<typeof Swiper | null>(null);
const swiperContentsRef = ref<typeof Swiper | null>(null);
const onSwiperPicturesInit = (swiper: any) => {
    swiperPicturesRef.value = swiper as typeof Swiper;
    swiperPicturesRef.value.on('slideChange', function () {
        handleOnSwiperPicturesMiddleChanged();
    });
};
const onSwiperContentsInit = (swiper: any) => {
    swiperContentsRef.value = swiper as typeof Swiper;
};

const handlePrevSlide = () => {
    if (swiperPicturesRef.value === null || swiperPicturesRef.value === null) return;
    swiperPicturesRef.value.slidePrev();
};
const handleNextSlide = () => {
    if (swiperPicturesRef.value === null || swiperPicturesRef.value === null) return;
    swiperPicturesRef.value.slideNext();
};
const handleOnSwiperPicturesMiddleChanged = (...args: unknown[]) => {
    if (swiperPicturesRef.value === null || swiperPicturesRef.value === null) return;
    if (swiperContentsRef.value === null || swiperContentsRef.value === null) return;
    let index = swiperPicturesRef.value.realIndex;
    swiperContentsRef.value.slideTo(index);
};
</script>

<template>
    <div class="v-home-cities">
        <div class="container">
            <VWidgetHeading align="center" :subtitle="cities.name || 'Popular Cities'" :title="cities.summary" :description="cities.content" />
            <div class="swiper-pictures">
                <swiper @swiper="onSwiperPicturesInit" :slides-per-view="5" :loop="true" :modules="[EffectLineerCebir]" :centeredSlides="true" @onMiddleChanged="handleOnSwiperPicturesMiddleChanged">
                    <swiper-slide v-for="(item, index) in city_items" :key="index">
                        <CioLink name="page" :params="{ slug: item.link.slug || '' }" root="app" tag="a" class="circle" :title="item.name">
                            <img :src="item.image" :alt="item.name" :title="item.name" loading="lazy" v-if="item.image" />
                        </CioLink>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="swiper-contents" v-if="city_items.length">
                <!-- !! DRAG iptal -->
                <swiper @swiper="onSwiperContentsInit" :effect="'fade'" :modules="[EffectFade]" :fade-effect="{ crossFade: true }" :allow-touch-move="false">
                    <swiper-slide v-for="(item, i) in city_items" :key="i">
                        <div class="texts">
                            <div class="name">
                                <VButton icon="arrow-left-line" pill color="secondary" variant="outlined" @click="handlePrevSlide" />
                                <CioLink name="page" :params="{ slug: item.link.slug || '' }" root="app" tag="a" :title="item.name">
                                    {{ item.name }}
                                </CioLink>
                                <VButton icon="arrow-right-line" pill color="secondary" variant="outlined" @click="handleNextSlide" />
                            </div>
                            <div class="content">
                                {{ item.content }}
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>
