<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue';

import VFaqs from '@/components/Faq/Faqs.vue';
// import VPopularCitiesWidget from '@/components/PopularCities/Widget.vue';
// import VReviewsWidget from '@/components/Reviews/Widget.vue';
// import VVehicleShowcaseWidget from '@/components/VehicleShowcase/Widget.vue';
// import VAirportShowcaseWidget from '@/components/AirportShowcase/Widget.vue';
import VHomeAdvantages from '@/components/Home/Advantages.vue';
import VHomeAirports from '@/components/Home/Airports.vue';
import VHomeBlogs from '@/components/Home/Blogs.vue';
import VHomeCampaigns from '@/components/Home/Campaigns.vue';
import VHomeCities from '@/components/Home/Cities.vue';
import VHomeReviews from '@/components/Home/Reviews.vue';
import VHomeVehicles from '@/components/Home/Vehicles.vue';
import VSearchArea from '@/components/Widgets/Hero/VSearchArea.vue';
import VMobileAppAds from '@/components/Widgets/MobileAppAds/VMobileAppAds.vue';
import { useCioView } from '@/composables/useCioView';
import appSettings from '@/definitions/settings.json';
import { useAppStore } from '@/stores/app-store';
import { useNodeStore } from '@/stores/node-store';
import { usePageHomeStore } from '@/stores/page-home-store';
import { defineOrganization, defineWebSite, useSchemaOrg } from '@vueuse/schema-org';

const appStore = useAppStore();
const nodeStore = useNodeStore();
const contentStore = usePageHomeStore();
const locale = computed(() => appStore.locale.path);
const cioView = await useCioView('page', locale);

const nodeData = ref(nodeStore.getData);
const contentData = ref(contentStore.getData);

onBeforeMount(async () => {
    await loadContent();
});

watch(
    () => nodeStore.loading,
    async (loadingValue) => {
        if (!loadingValue) {
            await loadContent();
        }
    }
);

const addSchema = async () => {
    // const url = cioRouter.getHref({ name: 'app.' + locale.value + '.home' }, true);
    const descriptions: { [key: string]: string } = appSettings.company.description;

    cioView.addSchema([
        defineOrganization({
            alternateName: appSettings.company.nickname,
            description: typeof descriptions[locale.value] === 'undefined' ? appSettings.company.description.default : descriptions[locale.value],
            email: appSettings.company.email,
            // host: url === null ? import.meta.env.VITE_APP_URL : url,
            logo: `${appStore.cdnUrl}/images/logo.svg`,
            name: appSettings.company.name,
            sameAs: appSettings.socialLinks.map((i) => i.url),
            telephone: appSettings.company.phoneNumber
            // url: url === null ? undefined : url
        }),
        defineWebSite({
            // inLanguage: appSettings.enabledLocales,
            inLanguage: locale.value,
            name: appSettings.company.name
        })
    ]);
};

const loadContent = async () => {
    contentData.value = contentStore.getData;

    if (nodeData.value.loaded && !contentData.value.loaded) {
        await contentStore.setFormApi(nodeData.value.idKey + appStore.locale.path);
        contentData.value = contentStore.getData;
    }

    contentData.value = contentStore.getData;
    await cioView.setHead();
    await addSchema();
};

if (import.meta.env.SSR) {
    await loadContent();
    useSchemaOrg(cioView.schemaList);
}
</script>

<template>
    <section class="page" :class="appStore.capacitor.platform === 'web' ? '' : 'page-home-native'" id="page-home">
        <VSearchArea />

        <!-- Advantages -->
        <template v-if="contentData.advantages && contentData.advantages.isExists && appStore.capacitor.platform === 'web'">
            <VHomeAdvantages :advantages="contentData.advantages" />
        </template>

        <!-- Airports -->
        <template v-if="contentData.airports && contentData.airports.isExists && appStore.capacitor.platform === 'web'">
            <VHomeAirports :airports="contentData.airports" />
        </template>

        <!-- Reviews -->
        <template v-if="contentData.reviews && contentData.reviews.isExists">
            <VHomeReviews :reviews="contentData.reviews" />
        </template>

        <!-- Campaigns -->
        <template v-if="contentData.campaigns && contentData.campaigns.isExists && appStore.capacitor.platform === 'web'">
            <VHomeCampaigns :campaigns="contentData.campaigns" />
        </template>

        <!-- Cities -->
        <template v-if="contentData.cities && contentData.cities.isExists && appStore.capacitor.platform === 'web'">
            <VHomeCities :cities="contentData.cities" />
        </template>

        <!-- Vehicles -->
        <template v-if="contentData.vehicles && contentData.vehicles.isExists && appStore.capacitor.platform === 'web'">
            <VHomeVehicles :vehicles="contentData.vehicles" />
        </template>

        <!-- Faqs -->
        <template v-if="contentData.faqs && contentData.faqs.isExists && appStore.capacitor.platform === 'web'">
            <VFaqs
                :items="
                    contentData.faqs.items.map((item) => ({
                        clearContent: item.content,
                        content: item.content,
                        group: '',
                        locale: '',
                        name: item.title,
                        orderRank: 0,
                        publishedAt: '',
                        summary: item.title,
                        template: '',
                        updatedAt: ''
                    }))
                "
            />
        </template>

        <!-- Blogs -->
        <template v-if="contentData.airports && contentData.airports.isExists && appStore.capacitor.platform === 'web'">
            <VHomeBlogs :blogs="contentData.blogs" />
        </template>

        <VMobileAppAds v-if="!appStore.hideMobileAppWidget" />
    </section>
</template>
