<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import VSearchBar from '@/components/Search/Bar.vue';
import screen from '@/plugins/screen';
import { type HeroSlide, usePageHomeStore } from '@/stores/page-home-store';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper/modules';
import { useCioRouter } from '@/composables/useCioRouter';

const pageHomeStore = usePageHomeStore();
const cioRouter = useCioRouter();

const scrollableContainer = ref<HTMLElement | null>(null);
const containerElement = ref<HTMLElement | null>(null);
const percent = ref(0);
let isAreaActive = ref(false);
const onSwiperFrameRef = ref<typeof Swiper | null>(null);
const onSwiperFrameSlideIndexRef = ref<number>(0);
const onSwiperStepperRef = ref<typeof Swiper | null>(null);
const onSwiperFrameInit = (swiper: any) => {
    onSwiperFrameRef.value = swiper as typeof Swiper;
    swiper.on('slideChange', function () {
        if (onSwiperStepperRef.value === null) return;
        onSwiperStepperRef.value.slideTo(swiper.activeIndex);
        onSwiperFrameSlideIndexRef.value = swiper.activeIndex;
    });
};
const heroSlideItems = computed(() => pageHomeStore.heroSlides);

// onMounted
onMounted(() => {
    if (!import.meta.env.SSR && screen.value.isDesktop) {
        scrollableContainer.value = document.getElementById('app') as HTMLElement;
        scrollableContainer.value?.addEventListener('scroll', handleScroll);
    }

    handleScroll();
});

// onUnmounted
onUnmounted(() => {
    if (!import.meta.env.SSR) {
        scrollableContainer.value?.removeEventListener('scroll', handleScroll);
    }
});

const handleHeroSlideClick = (item: HeroSlide) => {
    if (!item.link.isExists || item.link.slug === null) {
        return;
    }

    cioRouter.prepareOpen('app', item.link.group, { slug: item.link.slug });
};

const handleScroll = () => {
    if (screen.value.isMobile) return;

    if (!containerElement.value) return;
    const rect = containerElement.value.getBoundingClientRect();
    const height = containerElement.value.clientHeight;
    const top = rect.top * -1;
    percent.value = Math.min(1, Math.max(0, top / height));
};
</script>

<template>
    <div
        ref="containerElement"
        class="v-hero-search-area"
        :class="{ active: isAreaActive && screen.isDesktop }"
        :style="{
            '--percent': percent,
            '--percent-string': percent * 100 + '%'
        }"
    >
        <div v-if="screen.isDesktop" class="area">
            <div class="texts">
                <div class="t" v-html="pageHomeStore.sloganTexts.title" />
                <div class="d" v-html="pageHomeStore.sloganTexts.caption" />
            </div>
        </div>
        <div v-else class="swipers">
            <swiper :autoplay="{ delay: 3000, disableOnInteraction: true }" :modules="[Autoplay, Pagination]" :pagination="true" :slides-per-view="1" @swiper="onSwiperFrameInit">
                <swiper-slide v-for="(item, i) in heroSlideItems" :key="i">
                    <div class="area" @click="handleHeroSlideClick(item)">
                        <div v-if="item.image !== null && item.mode !== 'text'" class="image" :style="`background-image: url(${item.image});`"></div>
                        <div v-if="item.mode !== 'image' && (item.title !== null || item.caption !== null)" class="texts">
                            <div v-if="item.title !== null" class="t" v-html="item.title" />
                            <div v-if="item.caption !== null" class="d" v-html="item.caption" />
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="box">
            <VSearchBar :circle-button="screen.isDesktop" jump-to-list-on-search :summary-visible="false" />
        </div>
        <div v-if="screen.isDesktop" class="paralax">
            <template v-for="(image, iIndex) in pageHomeStore.parallaxImages" :key="iIndex">
                <img :src="image" alt="" loading="lazy" />
            </template>
        </div>
    </div>
</template>
