<script setup lang="ts">
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import CioLink from '@/components/CioLink.vue';
import VWidgetHeading from '@/components/Widgets/VWidgetHeading.vue';
import type { IVehicles } from '@/stores/page-home-store';
import { useAppStore } from '@/stores/app-store';

const props = defineProps<{
    vehicles: IVehicles;
}>();

const appStore = useAppStore();
</script>

<template>
    <div class="v-home-vehicles">
        <div class="container">
            <VWidgetHeading align="center" :subtitle="props.vehicles.name || 'Araçlarımızı Keşfedin'" :title="props.vehicles.summary" :description="props.vehicles.content || ''" />
            <div class="v-home-vehicles-list">
                <swiper
                    :modules="[Pagination]"
                    :pagination="true"
                    :breakpoints="{
                        0: {
                            // autoHeight: true,
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                            spaceBetween: 24
                        },
                        768: {
                            // autoHeight: true,
                            slidesPerView: 2,
                            slidesPerGroup: 1,
                            spaceBetween: 24
                        },
                        1050: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            spaceBetween: 24
                        }
                    }"
                >
                    <swiper-slide :key="vIndex" v-for="(vehicle, vIndex) in props.vehicles.items">
                        <CioLink class="v-home-vehicles-item" name="page" :params="{ slug: vehicle.link.slug || ' ' }" root="app" tag="a" :title="vehicle.name">
                            <img :src="vehicle.image || `${appStore.cdnUrl}/images/theme/default-vehicle.webp`" :alt="vehicle.name || ''" :title="vehicle.name" loading="lazy" />
                            <div class="content">
                                <h3>{{ vehicle.name }}</h3>
                                <p v-html="vehicle.content"></p>
                            </div>
                        </CioLink>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>
