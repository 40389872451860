<script setup lang="ts">
import { Grid, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import CioLink from '@/components/CioLink.vue';
import VButton from '@/components/Forms/VButton.vue';
import VWidgetHeading from '@/components/Widgets/VWidgetHeading.vue';
import type { IAirports } from '@/stores/page-home-store';
import { useAppStore } from '@/stores/app-store';

defineProps<{
    airports: IAirports;
}>();

const appStore = useAppStore();
</script>

<template>
    <div class="v-home-airports">
        <div class="container">
            <VWidgetHeading align="center" :subtitle="airports.name || 'Airports'" :title="airports.summary" :description="airports.content || ''" />
            <div class="v-home-airports-list">
                <swiper
                    :modules="[Grid, Pagination]"
                    :pagination="true"
                    :grid="{ rows: 2 }"
                    :slidesPerView="3"
                    :slidesPerGroup="6"
                    :breakpoints="{
                        0: {
                            // autoHeight: true,
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                            spaceBetween: 10,
                            grid: {
                                rows: 1
                            }
                        },
                        768: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                            spaceBetween: 10,
                            grid: {
                                rows: 1
                            }
                        },
                        1050: {
                            slidesPerView: 3,
                            slidesPerGroup: 6,
                            grid: {
                                rows: 2
                            },
                            spaceBetween: 10
                        }
                    }"
                >
                    <swiper-slide v-for="(airport, index) in airports.items" :key="index">
                        <CioLink class="v-home-airports-item" name="page" :params="{ slug: airport.link.slug || ' ' }" root="app" tag="a" :title="airport.name">
                            <img :src="airport.image || `${appStore.cdnUrl}/images/theme/default-airport.webp`" :alt="airport.name || ''" loading="lazy" />
                            <div class="content">
                                <h3>{{ airport.name }}</h3>
                                <p>{{ airport.content }}</p>
                                <div class="actions">
                                    <!-- <VButton
                                        prepend-icon="map-2-line"
                                        color="secondary"
                                        variant="outlined"
                                        v-if="airport.deliveryPoint.isExists"
                                    ><span>See on Map</span></VButton>  -->
                                    <VButton append-icon="arrow-right-line" color="secondary" variant="outlined"
                                        ><span>{{ $t('vehicle.card.details') }}</span></VButton
                                    >
                                </div>
                            </div>
                        </CioLink>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>
