<script setup lang="ts">
import { Grid, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import VWidgetHeading from '@/components/Widgets/VWidgetHeading.vue';
import { useAppStore } from '@/stores/app-store';
import type { IReviews } from '@/stores/page-home-store';

defineProps<{
    reviews: IReviews;
}>();

const appStore = useAppStore();
</script>

<template>
    <div class="v-home-reviews">
        <div class="container">
            <VWidgetHeading
                dark
                :align="appStore.capacitor.platform === 'web' ? 'center' : 'left'"
                :subtitle="reviews.name"
                :title="appStore.capacitor.platform === 'web' ? reviews.summary : null"
                :description="appStore.capacitor.platform === 'web' ? reviews.content : null"
            />
            <div class="v-home-reviews-list">
                <swiper
                    :modules="[Grid, Pagination]"
                    :pagination="true"
                    :breakpoints="{
                        0: {
                            // autoHeight: true,
                            slidesPerView: 1,
                            slidesPerGroup: 1
                        },
                        768: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                            spaceBetween: 10,
                            grid: {
                                rows: 1
                            }
                        },
                        1050: {
                            slidesPerView: 3,
                            grid: {
                                rows: 2
                            },
                            spaceBetween: 0
                        }
                    }"
                >
                    <swiper-slide :key="index" v-for="(review, index) in reviews.items">
                        <div class="v-home-reviews-item">
                            <div class="name">{{ review.name }}</div>
                            <div class="content" v-if="review.content">{{ review.content }}</div>
                            <div class="bottom">
                                <img :src="review.user.image" :alt="review.user.name.replace(/<[^>]*>?/gm, '')" loading="lazy" v-if="review.user.image" />
                                <div class="right">
                                    <!-- <span v-if="review.user.name" v-html="review.user.name.replace(/\*/g, '<c>●</c>')"></span> -->
                                    <span v-if="review.user.name" v-html="review.user.name"></span>
                                    <i>{{ review.user.content }}</i>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>
