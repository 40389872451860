const EffectLineerCebir = (args : any) => {
    const swiper: any = args.swiper; 
    const on = args.on; 
 

    const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
    const coord = (radiusX: number, radiusY: number, angle: number) => {
        const r = angle * Math.PI / 180;
        return {
            x: radiusX * Math.cos(r),
            y: radiusY * Math.sin(r)
        };
    }
    const bezier = (t: number, initial: number, p1: number, p2: number, final: number) => {
        return (
            (1 - t) * (1 - t) * (1 - t) * initial +
            3 * (1 - t) * (1 - t) * t * p1 +
            3 * (1 - t) * t * t * p2 +
            t * t * t * final
        );
    }
 

    let lastMiddleSlideIndex = -1;

    const setTranslate = () => {
        const {
            width: swiperWidth,
            height: swiperHeight,
            slides,
            slidesSizesGrid
        } = swiper;

        const transform = swiper.translate;
        const center = -transform + swiperWidth / 2;
        const swiperCenter = (swiperWidth / 2) - transform;

        let middleSlideOffset = Number.MAX_VALUE;
        let middleSlideIndex = -1;
 

        for (let i = 0, length = slides.length; i < length; i += 1) {
            const slideEl = slides[i];
            const slideWidth = slidesSizesGrid[i];
            const slideHeight = slideWidth * 2.5;
            const slideOffset = slideWidth * i;
            const slideCenterOffset = slideOffset + slideWidth / 2;
            const offset = slideCenterOffset - swiperCenter;
            const offsetAbs = Math.abs(offset);

            // slideEl.innerHTML = slideEl.getBoundingClientRect();
            // return;


            const circleEl = slideEl.querySelector('.circle');
            if (!circleEl) continue;
 

            const ratio = (offsetAbs) / (swiperWidth / 2);

            const scale = clamp(1 - ratio * 2, 0.1, 1);

            circleEl.style.transform = `translateX(-50%) translateY(-50%) scale(${scale})`;


            const degreeRatio = bezier(ratio, 0, 60, 30, 70);
            const degree = offset < 0 ? 90 + degreeRatio : 90 - degreeRatio;

            const {
                x,
                y
            } = coord(
                slideWidth * 2.5,
                slideHeight,
                degree
            );
            // circleEl.innerHTML = `${ratio.toFixed(2)}`;

            circleEl.style.top = `${y*-1 + slideHeight}px`;
            if (offset < 0) {
                circleEl.style.left = `calc(50% + ${ x + offsetAbs }px)`;
            } else {
                circleEl.style.left = `calc(50% + ${ x - offsetAbs }px)`;
            }

            if (offsetAbs > (swiperWidth / 2) * 0.8) {
                const elapsed = offsetAbs - (swiperWidth / 2) * 0.8;
                circleEl.style.opacity = 1 - (elapsed / 50);
            } else {
                circleEl.style.opacity = '1';
            }

            if(offsetAbs < middleSlideOffset) {
                middleSlideOffset = offsetAbs;
                middleSlideIndex = slideEl.getAttribute('data-swiper-slide-index');
            }
        }

        if(lastMiddleSlideIndex !== middleSlideIndex) {
            lastMiddleSlideIndex = middleSlideIndex;
            swiper.emit('onMiddleChanged', middleSlideIndex);
        } 
    };

    const setTransition = (duration: number) => {
        const circles = swiper.slides.map((slideEl: HTMLElement) => slideEl.querySelector('.circle'));
        circles.forEach((el: HTMLElement) => {
            el.style.transitionDuration = `${duration}ms`;
        });
    };

    const resize = () => {
        const {
            width: swiperWidth,
            height: swiperHeight,
            slides,
            slidesSizesGrid
        } = swiper;
        const height = slidesSizesGrid[0] * 1.5;
        const columns = swiper.slides.map((slideEl: HTMLElement) => slideEl.querySelector('.column'));
        swiper.slides.forEach((el: HTMLElement) => {
            el.style.height = `${height}px`;
        });
    };

    // let isUpdateVirtual = false;
    // const setVirtualUpdate = () => {
    //     if(!isUpdateVirtual) return;
    //     requestAnimationFrame(() => {
    //         console.log('setVirtualUpdate');
    //         setTranslate();
    //         setVirtualUpdate();
    //     });
    // }

    on('init', () => {
        resize();
        setTranslate();
    });
    on('setTranslate', () => {
        setTranslate();
    });
    on('transitionEnd', () => {
        setTranslate();
    });
    on('momentumBounce', () => {
        setTranslate();
    });
    on('setTransition', (_s: any, duration: any) => {
        setTransition(duration);
    });
    on('resize', () => {
        resize();
    }); 
    
    // on('transitionStart', () => {
    //     isUpdateVirtual = true;
    //     // setVirtualUpdate();
    // }); 
    // on('transitionEnd', () => {
    //     isUpdateVirtual = false;
    // });

 
}

export default EffectLineerCebir;